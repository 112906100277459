import { router } from '../../utils';
import { claimLotteryTicket } from './claimLotteryTicket';
import { createUser } from './createUser';
import { createUserV2 } from './createUserV2';
import { disconnectUserWallet } from './disconnectUserWallet';
import { getEligibleCommunities } from './getEligibleCommunities';
import { getLastUnseenLottery } from './getLastUnseenLottery';
import { getUser } from './getUser';
import { getUserByField } from './getUserByField';
import { getUserChallengeProgress } from './getUserChallengeProgress';
import { getUserCommunities } from './getUserCommunities';
import { hasUserClaimedLotteryTicketToday } from './hasUserClaimedLotteryTicketToday';
import { searchUserCommunities } from './searchUserCommunities';
import { updateUser } from './updateUser';
import { updateUserEmail } from './updateUserEmail';
export const userContract = router({
    getLastUnseenLottery: getLastUnseenLottery,
    claimLotteryTicket: claimLotteryTicket,
    hasUserClaimedLotteryTicketToday: hasUserClaimedLotteryTicketToday,
    getEligibleCommunities: getEligibleCommunities,
    getUserChallengeProgress: getUserChallengeProgress,
    create: createUser,
    createV2: createUserV2,
    get: getUser,
    update: updateUser,
    updateUserEmail: updateUserEmail,
    getUserByField: getUserByField,
    getUserCommunities: getUserCommunities,
    searchUserCommunities: searchUserCommunities,
    disconnectUserWallet: disconnectUserWallet,
}, {
    pathPrefix: '/users',
});
