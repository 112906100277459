'use client';
import { useQuery } from '@tanstack/react-query';
import { usersKeys } from '../users.keys';
import { getLastUnseenLottery } from '../users.service';
export const useLastUnseenLottery = () => useQuery({
    queryKey: usersKeys.getLastUnseenLottery(),
    queryFn: getLastUnseenLottery,
    staleTime: Infinity, // Cache for entire session
    gcTime: Infinity, // Keep in cache forever during session
    refetchOnMount: false,
});
