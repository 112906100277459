import { router } from '../../utils';
import { checkMember } from './checkMember';
import { createCommunitySecret } from './createCommunitySecret';
import { deleteZealyConnectForMember } from './deleteZealyConnectForMember';
import { getBasicInfo } from './getBasicInfo';
import { listCommunitySecret } from './listCommunitySecret';
export const publicCommunityContract = router({
    checkMember,
    getBasicInfo,
    deleteZealyConnectForMember,
    createCommunitySecret,
    listCommunitySecret,
}, {
    pathPrefix: '/communities',
});
