import { claimLotteryTicketOutputSchema } from '@zealy/schemas';
import { mutation } from '../../utils';
export const claimLotteryTicket = mutation({
    method: 'POST',
    path: '/me/challenge/claim',
    body: null,
    responses: {
        200: claimLotteryTicketOutputSchema,
    },
});
