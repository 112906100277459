import { z } from 'zod';
export const getEligibleCommunitiesOutputSchema = z.object({
    result: z.array(z.object({
        completed: z.boolean(),
        data: z.object({
            id: z.string(),
            image: z.string(),
            subdomain: z.string(),
            name: z.string(),
        }),
    })),
});
