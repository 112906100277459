export const usersKeys = {
    user: (id, subdomain) => subdomain ? ['user', id || 'me', subdomain] : ['user', id || 'me'],
    activity: (id, subdomain) => ['activity', id || 'me', subdomain],
    invites: (id, subdomain, status) => ['invites', id, subdomain, status],
    invitesCount: (id, subdomain, status) => [
        'invites',
        'count',
        id,
        subdomain,
        status,
    ],
    byField: (field, value) => ['user', field, value],
    challengeProgress: () => ['challenge', 'progress'],
    eligibleCommunities: () => ['challenge', 'eligibleCommunities'],
    hasUserClaimedLotteryTicketToday: () => ['challenge', 'hasUserClaimedLotteryTicketToday'],
    getLastUnseenLottery: () => ['challenge', 'getLastUnseenLottery'],
};
