import { hasUserClaimedLotteryTicketTodayOutputSchema } from '@zealy/schemas';
import { query } from '../../utils';
export const hasUserClaimedLotteryTicketToday = query({
    method: 'GET',
    path: '/me/challenge/ticket',
    body: {},
    responses: {
        200: hasUserClaimedLotteryTicketTodayOutputSchema,
    },
});
