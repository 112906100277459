import { createCommunitySecretOutputSchema } from '@zealy/schemas';
import { mutation, noBody } from '../../utils';
export const createCommunitySecret = mutation({
    method: 'POST',
    path: '/:subdomain/secrets',
    body: noBody(),
    responses: {
        201: createCommunitySecretOutputSchema.omit({ deletedAt: true }),
    },
});
