import { z } from 'zod';
export const sendOtpBodySchema = z
    .object({
    email: z.string().email().max(255),
    invitationId: z.string().optional(),
    reCaptchaToken: z.string().optional(),
    turnstileToken: z.string().optional(),
})
    .refine(data => data.reCaptchaToken || data.turnstileToken, {
    message: 'At least one of reCaptchaToken or turnstileToken is required',
});
export const sendOtpInputSchema = sendOtpBodySchema;
