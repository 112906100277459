import { z } from 'zod';
export const getLastUnseenLotteryOutputSchema = z
    .object({
    winner: z.object({
        name: z.string(),
        avatar: z.string(),
    }),
    participants: z.array(z.object({
        id: z.string(),
        name: z.string(),
        avatar: z.string(),
    })),
})
    .or(z.literal(false));
