import { z } from 'zod';
export const walletLoginBodySchema = z
    .object({
    sessionId: z.string(),
    signature: z.string(),
    invitationId: z.string().optional(),
    reCaptchaToken: z.string().optional(),
    turnstileToken: z.string().optional(),
})
    .refine(data => {
    return data.reCaptchaToken || data.turnstileToken;
}, {
    message: 'Missing captcha token',
});
export const walletLoginInputSchema = walletLoginBodySchema;
